import { useEffect } from 'react'
import Router from 'next/router'
import NProgress from 'nprogress'

export const usePageProgress = (
  routeDidChange?: (url: string) => void,
  routeWillChange?: (url: string) => void
) => {
  useEffect(() => {
    const cachedPageHeight: any[] = []
    let currentUrl: string

    let nextScrollPosition = 0

    window.history.scrollRestoration = 'manual'

    Router.events.on('routeChangeStart', () => {
      currentUrl = Router.asPath.split('?')[0]
      cachedPageHeight.push(window.scrollY)
      NProgress.start()
      routeWillChange && routeWillChange(currentUrl)
    })

    Router.events.on('routeChangeComplete', (url) => {
      window.scrollTo(0, nextScrollPosition)
      nextScrollPosition = 0
      NProgress.done()
      if (currentUrl !== url.split('?')[0]) {
        routeDidChange && routeDidChange(url)
      }
    })

    Router.events.on('routeChangeError', () => {
      NProgress.done()
    })

    Router.beforePopState(() => {
      nextScrollPosition = cachedPageHeight.pop() || 0
      return true
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}